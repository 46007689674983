import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { IssuedInvoiceListProps } from '../../api/issued-invoice/IssuedInvoiceDto';
import { useShallowEqualSelector } from '../../hooks/useShallowSelector';
import { profileSelector } from '../../reducers/authReducer';
import { CheckUserClaim } from '../../utils/CheckUserInfo';

import TableButton from '../button/TableButton';
import EditIcon from '../icons/EditIcon';
import MessageIcon from '../icons/MessageIcon';
import PrintIcon from '../icons/PrintIcon';
import Table from '../table/Table';
import {DateFormatter} from "../../utils/DateFormatter";
import {useMerchantApiContext} from "../../api/merchant/MerchantApiContext";
import {useNavigate, useParams} from "react-router-dom";
import {useAccountApiContext} from "../../api/account/AccountApiContext";
import {string} from "yup";
import DeleteIcon from "../icons/DeleteIcon";
import {toast} from "react-toastify";
import {PaginationNumberFormatterParams} from "ag-grid-community";
import {IDateFilterParams} from "@ag-grid-community/core";
import {AgGridReact} from "ag-grid-react";
import moment from "moment-timezone";
import Modal from "../modal/Modal";
import YesOrNoModal from "../app/YesOrNoModal";
import {useIssuedInvoiceApiContext} from "../../api/issued-invoice/IssuedInvoiceContext";

interface Props {
    readonly data: IssuedInvoiceListProps[];
    readonly loading: boolean;
    readonly selectRow: (value: any) => void;
    readonly onPrint: (value: any) => void;
    readonly onSend: (value: any) => void;
    readonly selectRowCheckbox: (value: any) => void;
}

export default function IssuedInvoiceTable({
    data,
    loading,
    selectRowCheckbox,
    onSend,
    selectRow,
    onPrint,
}: Props) {
    const { IssuedInvoiceApi } = useIssuedInvoiceApiContext();

    const navigator = useNavigate();
    const {AccountApi} = useAccountApiContext();

    const profile: any = useShallowEqualSelector(profileSelector);
    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);

            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );

    const [isDelModal, setIsDelModal] = useState(false);
    const [currentParams, setCurrentParams] = useState(0);
    const {tab = "1"} = useParams();

    const [tableHeaders, setTableHeaders] = useState([]);

    const [headers] = useState([
        {
            header: '№',
            access: 'id',
            width: 40,
        },
        {
            header: 'Номер счета',
            access: 'number',
            width: 200,
        },
        {
            header: 'Наименование счета',
            access: 'name',
            width: 200,
        },
        {
            header: 'Баланс',
            access: 'balance',
            width: 80,
        },
        {
            header: 'Валюта',
            access: 'currencyCode',
            width: 50,
        },
        {
            header: 'Филиал',
            access: 'branchName',
            width: 80,
        },

        // {
        //     header: 'Юр. лицо',
        //     access: 'merchantName',
        //     width: 200,
        // },

        // {
        //     header: 'Пользователь',
        //     access: 'userName',
        //     width: 200,
        // },
        {
            header: 'Тип счета',
            access: 'accountType',
            width: 80,
        },
        {
            header: 'Состояние',
            access: 'isActive',
            width: 80,
            ceil: (row: any) => {
                if (row.isActive) {
                    return (
                        <>
                            Активный
                        </>
                    );
                } else {
                    return (
                        <>
                            Заблокирован
                        </>
                    );
                }

            },
        },

        {
            header: 'Дата',
            access: 'dateCreated',
            width: 80,
        },
        {
            header: '...',
            access: 'edit',
            ceil: (row: any) => {
                return (
                    <div className="d-flex">
                        {CheckUserClaim(profile, 'UpdateAccount') ? (
                            <TableButton
                                className="bg-warning"
                                onClick={() => selectRow(row)}
                            >
                                <EditIcon color="white" size={14}/>
                            </TableButton>
                        ) : (
                            <div/>
                        )}
                        {CheckUserClaim(profile, 'DeleteAccount') ? (
                            <TableButton
                                className="bg-danger ms-2"
                                // onClick={() => deleteRow(row)}
                            >
                                <DeleteIcon color="white" size={14}/>
                            </TableButton>
                        ) : (
                            <div/>
                        )}
                    </div>
                );
            },
            width: 100,
        },
    ]);

    useEffect(() => {
        const data: any = [...headers];
        if (
            !CheckUserClaim(profile, 'UpdateAccount') &&
            !CheckUserClaim(profile, 'DeleteAccount')
        ) {
            data.splice(data.length - 1, 1);
        }
        setTableHeaders(data);
    }, [headers, profile]);


    const editButton = (params: any) => {
        // navigator(`/app/parcels/parcel-form?parcelId=${params.data.id}`);
        // navigator(`/app/parcel-form-second?parcelId=${params.data.id}`);
        navigator(`/app/issued-invoice/form?issuedId=${params.data.id}`)
    }

    const deleteButton = (params: any) => {
        navigator(`/app/manifest/form?manifestId=${params.data.id}`)
    }

    const checkClaim = useCallback(
        (claim: string) => {
            return Boolean(profile[claim]);
        },
        [profile]
    );

    const openModal = (params: any) => {
        setIsDelModal(true);
        setCurrentParams(params.data.id);
    };

    const deleteManifest = useCallback((id: any) => {
        AccountApi.deleteAccount({id: [id]})
            .then((response) => {
                toast.success(response.message);
                setIsDelModal(false);
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [setIsDelModal, AccountApi]);

    const datasource = {
        getRows(params: any) {
            const {startRow, endRow, filterModel, sortModel} = params.request
            let count = params.request.endRow - params.request.startRow; // or the cacheBlockSize
            let page = Math.ceil(params.request.endRow / count);

            const filterKeysNumberOfPoint = Object.keys(params.request.filterModel)
            filterKeysNumberOfPoint.forEach(filter => {
                if (filterKeysNumberOfPoint[0] === "numberOfPoint") {
                    // initialValues.numberOfPoint = "";
                    //   initialValues.numberOfPoint = `${filterModel[filter].filter}`
                }
            })
            //
            const filterKeys = Object.keys(filterModel)
            filterKeys.forEach(filter => {
                if (filterKeys[0] === "code") {
                    //   initialValues.code = "";
                    // initialValues.code += `${filterModel[filter].filter}`
                }
            })

            const filterKeysFromDate = Object.keys(filterModel)

            filterKeysFromDate.forEach(filter => {
                if (filterKeys[0] === "dateCreatedString") {
                    if (filterModel[filter].dateFrom !== null) {
                        // initialValues.fromDate = "";
                        // initialValues.fromDate += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        // initialValues.toDate = "";
                        // initialValues.toDate += `${filterModel[filter].dateTo}`
                    }
                }
            })

            const filterKeysFromDateStatus = Object.keys(filterModel)
            filterKeysFromDateStatus.forEach(filter => {
                if (filterKeys[0] === "dateCreatedStatus") {
                    if (filterModel[filter].dateFrom !== null) {
                        // initialValues.fromDateStatus = "";
                        // initialValues.fromDateStatus += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        // initialValues.toDateStatus = "";
                        // initialValues.toDateStatus += `${filterModel[filter].dateTo}`
                    }
                }
            })

            // const data = {
            //     pageNumber: page,
            //     pageSize: count === 0 ? 1 : count,
            //     code: Number(initialValues.code),
            //     numberOfPoint: Number(initialValues.numberOfPoint),
            //     fromBranch: selectedFromBranch || ['Москва', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул', 'Ташкент'],
            //     toBranch: selectedToBranch || ['Москва', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул', 'Ташкент'],
            //     planId: selectedPlan || ['Стандарт', 'Экспресс', 'Ультра', 'Авто', 'Авто-Эконом'],
            //     statusId: selectedStatus || ['Создан', 'В пути', 'Прибыл', 'У курьера'],
            //     parcelTypeId: selectedParcelType || ['Посылка', 'Документ', 'Мед'],
            //     payerType: selectedPayer || ['Отправитель', 'Получатель', 'Юр. лицо (ВЫСТАВЛЕН СЧЕТ)'],
            //     dateCreateStart: initialValues.fromDate,
            //     dateCreateEnd: initialValues.toDate,
            //     dateCreateStatusStart: initialValues.fromDateStatus,
            //     dateCreateStatusEnd: initialValues.toDateStatus,
            // };

            IssuedInvoiceApi.getAllIssuedInvoiceListWithPagn({
                pageNumber: page,
                pageSize: count,
                // accountTypeId: tab,
                // Add other necessary parameters here
            })
                .then((response: any) => {
                    params.success({
                        rowData: response.items,
                        rowCount: response.items.totalCount,

                    });
                })
                .catch((error: any) => {
                    if (error.message === '') {
                        toast.error('Посылка не найдена!');
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    };

    const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [20, 50, 100, 10000];
    }, []);

    const paginationNumberFormatter = useCallback(
        (params: PaginationNumberFormatterParams) => {
            return "[" + params.value.toLocaleString() + "]";
        },
        [],
    );

    const [gridApi, setGridApi] = useState(null);
    var dateFilterParams: IDateFilterParams = {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split(".");
            var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0]),
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2024,
        maxValidYear: 2025,
        inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    };
    const onGridReady = (params: any) => {
        setGridApi(params);
        // params.api.setServerSideDatasource(datasource);
        // params.api.setGridOption('serverSideDatasource', datasource)
        params.api.updateGridOptions({serverSideDatasource: datasource})
    }
    const gridOptions = {
        // other grid options ...
        getRowId: (params: any) => {
            // return the ID for the row data item
            return params.data.id;
        },
    };
    const gridRef = useRef<AgGridReact>(null);
    const [columnDefs, setColDefs] = useState([
        // {
        //     headerName: "...",
        //     minWidth: 30,
        //     maxWidth: 60,
        //     checkboxSelection: true
        // },
        {
            field: "id",
            headerName: "№",
            minWidth: 70,
            maxWidth: 70,
            filter: false,
            // filter: "agNumberColumnFilter"
        },
        {
            field: "code",
            headerName: "Код",
            minWidth: 85,
            maxWidth: 115,
            filter: false,
        },
        {
            field: "parcelCode",
            headerName: "Код посылки",
            minWidth: 120,
            maxWidth: 120,
            filter: false,
        },
        {
            field: "merchantName",
            headerName: "Контрагент",
            flex: 1,
            filter: false,
            // filter: "agNumberColumnFilter"
        },
        {
            headerName: "Филиал",
            field: "branchName",
            minWidth: 100,
            maxWidth: 100,
            filter: false,
            // filter: 'agSetColumnFilter',
            // filterParams: {
            //     values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            // }
        },
        {
            field: "status",
            headerName: "Статус",
            resizable: true,
            minWidth: 90,
            maxWidth: 90,
            filter: false,
            // filter: "agNumberColumnFilter", /*floatingFilter: true,*/
        },
        {
            field: "parcelCostUsd",
            headerName: "Стоимость",
            minWidth: 110,
            maxWidth: 110,
            filter: false,
            // filter: "agNumberColumnFilter", /*floatingFilter: true,*/
        },
        {
            field: "dateCreated",
            headerName: "Дата",
            minWidth: 100,
            maxWidth: 100,
            filter: false,
            cellRenderer: function (row: any) {
                return moment(row.data.dateCreated).format('DD.MM.yyyy')
            }
        },
        // {
        //     headerName: "...",
        //     field: "price",
        //     minWidth: checkRole('Administrator') ? 80 : 70,
        //     maxWidth: checkRole('Administrator') ? 80 : 70,
        //     cellRenderer: (params: any) =>
        //         <div className="btn-group" role="group" aria-label="Basic example">
        //             <button type="button" className={'btn btn-sm'} onClick={() => editButton(params)}><EditIcon
        //                 color="green" size={10}/></button>
        //
        //             {checkRole('Administrator') &&
        //                 <button type="button" className={'btn btn-sm'} onClick={() => openModal(params)}><DeleteIcon
        //                     color="red" size={10}/></button>
        //             }
        //         </div>
        // },
    ]);

    return (
        <>
            <div
                className="ag-theme-balham"
                style={{height: '100%'}}
            >
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowSelection={'multiple'}
                    pagination={true}
                    paginationPageSize={20}
                    enableCellTextSelection={true}
                    rowModelType={"serverSide"}
                    onGridReady={onGridReady}
                    // onFilterChanged={onFilterChanged}
                    rowBuffer={10000}
                    cacheBlockSize={20}
                    enableRangeSelection={true}
                    maxBlocksInCache={2}
                    // onRowDoubleClicked={() => myDatas(gridRef!.current!.api.getSelectedRows())}
                    // sideBar={sideBar}
                    gridOptions={gridOptions}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    paginationNumberFormatter={paginationNumberFormatter}
                    // getMainMenuItems={getMainMenuItems}
                    suppressMenuHide
                    defaultColDef={{filter: false, floatingFilter: false, sortable: false}}
                />
            </div>
            <Modal
                width="500px"
                show={isDelModal}
                closeHandler={() => setIsDelModal(false)}
                className="d-flex justify-content-center align-items-center"
            >
                <YesOrNoModal
                    titleText="Вы уверены, что хотите удалить?"
                    onClick={(value: boolean) => {
                        if (value) {
                            console.log(currentParams)
                            deleteManifest(currentParams);
                        } else {
                            setIsDelModal(false);
                        }
                    }}
                />
            </Modal>
        </>
    );
}