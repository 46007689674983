import {Form, Formik} from 'formik';
import React, {useCallback, useMemo} from 'react';
import {update} from 'immupdate';

import GroupBox from '../app/GroupBox';
import SelectPickerField from '../form/SelectPickerField';
import Button from '../button/Button';
import TextAreaField from '../form/TextAreaField';
import InputGroup from '../app/InputGroup';
import InputField from '../form/InputField';
import CheckBox from '../form/CheckBox';
import {CheckUserRole} from '../../utils/CheckUserInfo';
import {useShallowEqualSelector} from '../../hooks/useShallowSelector';
import {profileSelector} from '../../reducers/authReducer';
import EditParcelShowNumberForManifest from "./EditParcelShowNumberForManifest";

interface Props {
    readonly initialValues: any;
    readonly type: string;
    readonly branches: any[];
    readonly setInitialValues: (value: any) => void;
    readonly onSubmit: (value: any) => void;
    readonly accounts: any[];
    readonly currencyTypes: any[];
    readonly manifestTypes: any[];
    readonly driverTypes: any[];
    readonly statusManifest: any[];
    readonly usdRate: any;
}

export default function ManifestForm({
                                         initialValues,
                                         branches,
                                         setInitialValues,
                                         onSubmit,
                                         accounts,
                                         driverTypes,
                                         statusManifest,
                                         currencyTypes,
                                         manifestTypes,
                                         usdRate,
                                         type,
                                     }: Props) {
    const onChangeFromBranchId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    fromBranchId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );
    const onChangeAccountId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    accountId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );
    const onChangeDriverTypeId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    driverTypeId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );
    const onChangeStatusManifestId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    statusManifestId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );
    const onChangeToBranchId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    toBranchId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    const onChangeDescription = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    description: value.target.value,
                })
            );
        },
        [setInitialValues]
    );
    const onChangeCostUsd = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    costUsd: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeStateCostUsd = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    stateCostUsd: value,
                    // stateCostLocal: !value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeManifestTypeId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                manifestTypeId: {
                    label: value.label,
                    value: value.value,
                },

            })
        );
    }, [setInitialValues, initialValues]);


    const onChangeCurrencyTypeDeliveryToBranch = useCallback((value: any) => {
        // let localCost: any;
        // if (value.value !== 1) {
        //     localCost = initialValues.costDeliveryToBranch as any * usdRate // Explicitly specify the type
        // } else if (value.value == 1) {
        //     localCost = initialValues.costDeliveryToBranch as any / usdRate // Explicitly specify the type
        // }

        setInitialValues((prev: any) =>

            update(prev, {
                currencyId: {
                    label: value.label,
                    value: value.value,
                },

            })
        );
    }, [setInitialValues, initialValues]);


    const onChangeParcelCode = useCallback(
        (value: any) => {
            let array = [...initialValues.parcelItem];
            // Check if the input value contains only numbers
            const isNumeric = /^\d+$/.test(value.target.value)

            if (!isNumeric || value.target.value.startsWith('0')) {
                setInitialValues((prev: any) =>
                    update(prev, {
                        searchText: '',
                    })
                );
            }


            if (isNumeric && !value.target.value.startsWith('0')) {
                if (value.target.value.length === 9) {
                    let data = {
                        title: value.target.value,
                    };
                    let found = array.filter(
                        (item: any) => item.title === value.target.value
                    );

                    if (found.length === 0) {
                        array.push(data);
                    }

                    setInitialValues((prev: any) =>
                        update(prev, {
                            parcelItem: array,
                            searchText: '',
                        })
                    );
                } else {
                    setInitialValues((prev: any) =>
                        update(prev, {
                            searchText: value.target.value,
                        })
                    );
                }
            }

        },
        [setInitialValues, initialValues]
    );

    const deleteSearchNumber = useCallback(
        (value: any) => {
            let data = [...initialValues.parcelItem];

            data.splice(Number(value), 1);

            setInitialValues((prev: any) =>
                update(prev, {
                    parcelItem: data,
                })
            );
        },
        [setInitialValues, initialValues.parcelItem]
    );

    const profile: any = useShallowEqualSelector(profileSelector);
    const isAdminOrManager = useMemo(
        () =>
            CheckUserRole(profile?.role, 'Administrator') ||
            CheckUserRole(profile?.role, 'Manager'),
        [profile.role]
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {() => (
                <Form>
                    <InputField name="manifestCostId" value={initialValues.manifestCostId} type="hidden"/>
                    <div className="row p-4">
                        <div className="col-12">
                            <GroupBox>

                                <div className="row">
                                    <div className="col-12 mb-3 d-flex justify-content-between align-item-center">
                                        <div className="d-flex gap-2 align-item-center">
                                            <span>Номер манифеста</span>

                                            <span> {initialValues.code}</span>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <SelectPickerField
                                            disabled
                                            label="Филиал отправитель"
                                            name="fromBranchId"
                                            options={branches}
                                            onChanges={onChangeFromBranchId}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <SelectPickerField
                                            label="Филиал получатель"
                                            name="toBranchId"
                                            options={branches}
                                            onChanges={onChangeToBranchId}
                                        />
                                    </div>

                                    <div className="col-6 ">
                                        <SelectPickerField
                                            name="manifestTypeId"
                                            onChanges={(value: any) => onChangeManifestTypeId(value)}
                                            options={
                                                manifestTypes
                                            }
                                            label="Тип манифеста"
                                        />
                                    </div>

                                    <div className="col-4 mt-3">
                                        <InputGroup label="Стоимость перевозки">
                                            {/*<InputField*/}
                                            {/*    width={500}*/}
                                            {/*    disabled*/}
                                            {/*    inputClassName="border-0"*/}
                                            {/*    value={*/}
                                            {/*        'Стоимость перевозки оплачен?'*/}
                                            {/*    }*/}
                                            {/*    name="costDeliveryToBranchCheckUsd"*/}
                                            {/*/>*/}
                                            <CheckBox
                                                onChange={(event) =>
                                                    onChangeStateCostUsd(event)
                                                }
                                                value={
                                                    initialValues.stateCostUsd
                                                }
                                                disabled={
                                                    !isAdminOrManager &&
                                                    type === 'create'
                                                }
                                                name="stateCostUsd"
                                            />
                                            <InputField
                                                value={initialValues.costUsd}
                                                type="number"
                                                onChange={(event: any) =>
                                                    onChangeCostUsd(event)
                                                }
                                                inputClassName="rounded-0 border-0 h-100"
                                                name="costUsd"
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="col-2 mt-3">
                                        <SelectPickerField
                                            name="currencyId"
                                            onChanges={(value: any) =>
                                                onChangeCurrencyTypeDeliveryToBranch(value)
                                            }
                                            options={currencyTypes}
                                            label="Валюта"
                                        />
                                    </div>

                                    <div className="col-6 mt-3">
                                        <SelectPickerField
                                            options={accounts}
                                            onChanges={(value: any) =>
                                                onChangeAccountId(value)
                                            }
                                            name="accountId"
                                            label="Счет филиала"
                                        />
                                    </div>

                                    {type == 'update' && (
                                        <div className="col-6 mt-3">
                                            <SelectPickerField
                                                options={driverTypes}
                                                onChanges={(value: any) =>
                                                    onChangeDriverTypeId(value)
                                                }
                                                name="driverTypeId"
                                                label="Перевозчик"
                                            />
                                        </div>
                                    )}
                                    {type != 'update' && (
                                        <div className="col-12 mt-3">
                                            <SelectPickerField
                                                options={driverTypes}
                                                onChanges={(value: any) =>
                                                    onChangeDriverTypeId(value)
                                                }
                                                name="driverTypeId"
                                                label="Перевозчик"
                                            />
                                        </div>
                                    )}

                                    {type == 'update' && (
                                        <div className="col-6 mt-3">
                                            <SelectPickerField
                                                options={statusManifest}
                                                onChanges={(value: any) =>
                                                    onChangeStatusManifestId(
                                                        value
                                                    )
                                                }
                                                //onChanges={onChangeStatusManifestId}
                                                name="statusManifestId"
                                                label="Статус"
                                            />
                                        </div>
                                    )}

                                    <div className="col-12 mt-3">
                                        <TextAreaField
                                            name="description"
                                            label="Описание"
                                            value={initialValues.description}
                                            onChange={(value) =>
                                                onChangeDescription(value)
                                            }
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <InputField
                                            name="parcelItem"
                                            type="text"
                                            label={`Введите код посылки ${initialValues.id > 0 ? '(Зеленые - Прибывшие, Красные - Не прибывшие)' : ''}`}
                                            // label="Введите код посылки (Зеленый - Прибывшие, Красный - Не прибывшие)"
                                            value={initialValues.searchText}
                                            onChange={(value) =>
                                                onChangeParcelCode(value)
                                            }
                                        />
                                    </div>
                                    <div className="col-12 d-flex">
                                        <EditParcelShowNumberForManifest
                                            data={initialValues.parcelItem}
                                            color="bg-success"
                                            delet={(value: number) => deleteSearchNumber(value)}
                                            editable={initialValues.editable}
                                        />
                                    </div>
                                    <div className="col-12 mt-2">
                                        <Button
                                            className="bg-gold text-light px-3 py-1"
                                            type="submit"
                                        >
                                            Сохранить
                                        </Button>
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
