import {useEffect, useState, useCallback, useMemo} from 'react';
import {toast} from 'react-toastify';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useShallowEqualSelector} from '../../hooks/useShallowSelector';
import {profileSelector} from '../../reducers/authReducer';
import {useParcelApiContext} from '../../api/parcel/ParcelApiContext';
import TabPage from '../tabs/TabPage';
import ModalLoader from '../modal/MdalLoader';
import {useAccountApiContext} from '../../api/account/AccountApiContext';
import {useMerchantApiContext} from '../../api/merchant/MerchantApiContext';
import {useStatusApiContext} from '../../api/status/StatusApiContext';
import {useBranchApiContext} from '../../api/branch/BranchApiContext';
import {usePlanApiContext} from '../../api/plan/PlanApiContext';
import {usePayersTypeApiContext} from "../../api/payerstype/PayerTypeApiContext";
import AddParcelFormSecond from "./ParcelForm";
import {useCurrencyApiContext} from "../../api/currency/CurrencyApiContext";
import {useParcelTypeApiContext} from "../../api/parceltype/ParcelTypeApiContext";

const labelValue: SelectType = {
    label: '',
    value: '',
};

const getBranchName = (branchId: any) => {
    switch (branchId) {
        case 1:
            return 'Москва';
        case 2:
            return 'Ташкент';
        case 3:
            return 'Алматы';
        case 4:
            return 'Стамбул';
        case 5:
            return 'Бишкек';
        case 6:
            return 'Душанбе';
    }
};

interface SelectType {
    readonly label: string;
    readonly value: string | number;
}

export default function AddParcelFormWrapper() {
    const profile: any = useShallowEqualSelector(profileSelector);
    const [loading, setLoading] = useState(false);

    let myCurrencyId = 0
    let myCurrencyValue = ""

    let myParcelTypeId = 0
    let myParcelTypeValue = ""

    switch (Number(profile?.BranchId)) {
        case 1:
            myCurrencyId = 3;
            myCurrencyValue = "RUB";
            break;
        //Toshkent
        case 2:
            myCurrencyId = 1;
            myCurrencyValue = "USD";
            myParcelTypeId = 1;
            myParcelTypeValue = "Посылка"
            break;
        case 3:
            myCurrencyId = 1;
            myCurrencyValue = "USD";
            myParcelTypeId = 1;
            myParcelTypeValue = "Посылка"
            break;
        case 4:
            myCurrencyId = 1;
            myCurrencyValue = "USD";
            myParcelTypeId = 1;
            myParcelTypeValue = "Посылка"
            break;
        case 5:
            myCurrencyId = 6;
            myCurrencyValue = "KGS";
            break;
        case 6:
            myCurrencyId = 7;
            myCurrencyValue = "TJS";
            break;
        default:
            // Handle default case if needed
            break;
    }

    //SET INITIAL DATA
    const [initialValues, setInitialValues] = useState({
        code: 0,
        senderId: labelValue,
        payerType: labelValue,
        payerId: labelValue,
        merchantId: {
            label: '',
            value: 0,
        },
        recipientId: labelValue,
        parcelBranchFromId: {
            label: getBranchName(Number(profile?.BranchId)),
            value: Number(profile?.BranchId),
        },
        parcelBranchToId: labelValue,
        weight: 0,
        images: [],
        parcelItem: [],
        description: '',
        pickupAddress: '',
        deliveryAddress: '',
        numberOfPoint: 1,
        parcelPlanId: labelValue,
        parcelStatusId: labelValue,
        payerTypeId: {
            label: "Отправитель",
            value: 1,
        },

        customPayerTypeId: {
            label: "Получатель",
            value: 2,
        },

        currencyTypeIdForPickingUp: {
            label: myCurrencyValue,
            value: myCurrencyId,
        },

        currencyTypeIdForDeliveryToPoint: {
            label: myCurrencyValue,
            value: myCurrencyId,
        },

        parcelsTypeId:
            {
                label: myParcelTypeValue,
                value: myParcelTypeId,
            },
        ///
        costDeliveryToBranch: "",
        stateDeliveryToBranch: false,

        costDeliveryToBranch_parcelCostId: 0,

        ////
        costDeliveryToPoint: "",
        stateDeliveryToPoint: false,

        costDeliveryToPoint_parcelCostId: 0,

        ///
        costPickingUp: "",
        statePickingUp: false,

        costPickingUp_parcelCostId: 0,
        ///
        paymentMethod: labelValue,
        senderCourierId: labelValue,
        recipientCourierId: labelValue,

        stateSenderCourierId: false,
        stateRecipientCourierId: false,

        sendSmsToRecipient: false,
        sendSmsToSender: false,
        sendSmsToTelegram: true,

    });
    const [senders, setSenders] = useState<any[]>([]);
    const [recipients, setRecipients] = useState<any[]>([]);
    const [recipientStaffs, setRecipientStaffs] = useState<any[]>([]);
    const [branches, setBranches] = useState<any>([]);
    const [merchants, setMerchants] = useState<any>([]);
    const [costInfoList, setCostInfoList] = useState<any>([]);
    const [usdRate, setUsdRate] = useState<any>([]);
    const [usdRateList, setUsdRateList] = useState<any>([]);
    const [plans, setPlans] = useState<any>([]);
    const [payerTypes, setPayersType] = useState<any>([]);
    const [parcelTypes, setParcelType] = useState<any>([]);
    const [currencyTypes, setCurrencyTypes] = useState<any>([]);

    const [couriers, setCouriers] = useState<any>([]);
    const [accounts, setAccounts] = useState<any>([]);
    const [parcelItemStatuses, setParcelItemStatus] = useState<any[]>([]);
    const [search] = useSearchParams();
    const navigator = useNavigate();
    const {ParcelApi} = useParcelApiContext();
    const {StatusApi} = useStatusApiContext();
    const {MerchantApi} = useMerchantApiContext();
    const {AccountApi} = useAccountApiContext();
    const {BranchApi} = useBranchApiContext();
    const {PlanApi} = usePlanApiContext();
    const {PayersTypeApi} = usePayersTypeApiContext();
    const {ParcelTypeApi} = useParcelTypeApiContext();
    const {CurrencyApi} = useCurrencyApiContext();

    const parcelId = useMemo(() => (search.get('parcelId') ? search.get('parcelId') : ''), [search]);

    const getSendersBySearching = useCallback((value: string) => {
        if (value !== '') {
            ParcelApi.getSearchUserWithSkip(value)
                .then((response: any) => {
                    let array: any = [];
                    // eslint-disable-next-line array-callback-return
                    response.customers.map((item: any) => {
                        const data = {
                            label: `${item.firstName} ${item.lastName} ${item.phone}`,
                            value: item.id,
                        };
                        array.push(data);
                    });
                    setSenders(array);
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    }, [setSenders, ParcelApi]);

    const getRecipientsBySearching = useCallback((value: string) => {
        if (value !== '') {
            ParcelApi.getSearchUserWithSkip(value)
                .then((response: any) => {
                    let array: any = [];
                    // eslint-disable-next-line array-callback-return
                    response.customers.map((item: any) => {
                        const data = {
                            label: `${item.firstName} ${item.lastName} ${item.phone}`,
                            value: item.id,
                        };
                        array.push(data);
                    });
                    setRecipients(array);
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    }, [setRecipients, ParcelApi]);

    const getRecipientStaffsBySearching = useCallback((value: string) => {
        if (value !== '') {
            ParcelApi.getSearchUserWithSkip(value)
                .then((response: any) => {
                    let array: any = [];
                    // eslint-disable-next-line array-callback-return
                    response.customers.map((item: any) => {
                        const data = {
                            label: `${item.firstName} ${item.lastName} ${item.phone}`,
                            value: item.id,
                        };
                        array.push(data);
                    });
                    setRecipientStaffs(array);
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        }
    }, [setRecipients, ParcelApi]);

    useEffect(() => {
        MerchantApi.getMerchantList()
            .then((response: any) => {
                response.merchantes.map((item: any) => {
                    const data = {
                        label: `${item.tin} ${item.name}`,
                        value: item.id,
                    };
                    setMerchants((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setMerchants, MerchantApi]);


    const onPrint = useCallback((value: any) => {
        ParcelApi.getStickerById({id: value.id, code: value.code});
    }, [ParcelApi]);

    const printParcelItem = useCallback((value: any) => {
        ParcelApi.getStickerByCode({
            id: Number(parcelId),
            itemCode: value,
        });
    }, [ParcelApi, parcelId]);

    useEffect(() => {
        BranchApi.getBranchList()
            .then((response: any) => {
                // response.branches?.filter((item: any) => item.id === 3
                const newPeople = response.branches.filter((item: any) => item.id !== Number(profile?.BranchId));
                newPeople.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((user: any) => [...user, data]);

                });

            })
            .catch((error) => console.log(error.message));
    }, [setBranches, BranchApi]);

    useEffect(() => {
        PlanApi.getPlanList()
            .then((response: any) => {
                response.plans.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setPlans((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setPlans, PlanApi]);

    useEffect(() => {
        PayersTypeApi.getPayersTypeList()
            .then((response: any) => {
                response.payersTypeList.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setPayersType((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setPayersType, PayersTypeApi]);

    useEffect(() => {
        ParcelTypeApi.getParcelTypeList()
            .then((response: any) => {
                response.parcelTypeList.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setParcelType((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setParcelType, ParcelTypeApi]);

    useEffect(() => {
        CurrencyApi.getCurrencyTypeListSecond()
            .then((response: any) => {
                response.currencies.map((item: any) => {
                    const data = {
                        label: item.labelSecond,
                        value: item.value,
                    };
                    setCurrencyTypes((currencies: any) => [...currencies, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setCurrencyTypes, CurrencyApi]);

    useEffect(() => {
        ParcelApi.getInfoParcel()
            .then((response: any) => {
                setCostInfoList(response.costInfoList);
                setUsdRate(response.usdRate);

                setUsdRateList(response.rate);

            })
            .catch((error) => console.log(error.message));
    }, [setCostInfoList, setUsdRate, ParcelApi]);

    useEffect(() => {
        ParcelApi.getRoles(34)
            .then((response: any) => {
                // eslint-disable-next-line array-callback-return
                response.customers.map((item: any) => {
                    const data = {
                        label: `${item.firstName} ${item.lastName} ${item.phone}`,
                        value: item.id,
                    };
                    setCouriers((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setCouriers, ParcelApi]);

    useEffect(() => {
        AccountApi.getAccountList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: `${item.name} ${item.currencyCode} - ${item.paymentMethod}`,
                        value: item.id,
                    };
                    setAccounts((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setAccounts, AccountApi]);

    useEffect(() => {
        StatusApi.getAllStatusItemList()
            .then((response: any) => {
                // eslint-disable-next-line array-callback-return
                response.statusItems &&
                response.statusItems.map((status: any) => {
                    const data = {
                        label: status.name,
                        value: status.id,
                    };
                    setParcelItemStatus((status: any) => [...status, data]);
                });
            })
            .catch((error: any) => console.log(error));
    }, [StatusApi, setParcelItemStatus]);

    var _costPickingUp_parcelCostId: 0;
    var _costDeliveryToBranch_parcelCostId: 0;
    var _costDeliveryToPoint_parcelCostId: 0;

    ////getParcelDetailsById
    useEffect(() => {
        if (parcelId !== '') {
            setLoading(true);
            ParcelApi.getParcelDetailsById(parcelId)
                .then((response: any) => {
                    setLoading(false);


                    const value = response;

                    const _costPickingUp = response.parcelCost.filter((item: any) => item.costTypeId === 1);
                    const _costDeliveryToBranch = value.parcelCost.filter((item: any) => item.costTypeId === 2);
                    const _costDeliveryToPoint = response.parcelCost.filter((item: any) => item.costTypeId === 3);

                    var accountIdForCostPickingUp_Id = "";
                    var accountIdForCostPickingUp_Name = "";
                    var accountIdForCostDeliveryToBranch_Id = "";
                    var accountIdForCostDeliveryToBranch_Name = "";
                    var accountIdForCostDeliveryToPoint_Id = "";
                    var accountIdForCostDeliveryToPoint_Name = "";

                    if (_costPickingUp[0]?.amount > 0) {
                        accountIdForCostPickingUp_Id = _costPickingUp[0]?.accountId
                        accountIdForCostPickingUp_Name = _costPickingUp[0]?.accountName
                        _costPickingUp_parcelCostId = _costPickingUp[0]?.id
                    }

                    if (_costPickingUp[0]?.amount === 0) {
                        accountIdForCostPickingUp_Id = ""
                        accountIdForCostPickingUp_Name = ""
                        _costPickingUp_parcelCostId = 0
                    }

                    if (_costDeliveryToBranch[0]?.amount > 0) {
                        accountIdForCostDeliveryToBranch_Id = _costDeliveryToBranch[0]?.accountId
                        accountIdForCostDeliveryToBranch_Name = _costDeliveryToBranch[0]?.accountName
                        _costDeliveryToBranch_parcelCostId = _costDeliveryToBranch[0]?.id
                    }

                    if (_costDeliveryToPoint[0]?.amount > 0) {
                        accountIdForCostDeliveryToPoint_Id = _costDeliveryToPoint[0]?.accountId
                        accountIdForCostDeliveryToPoint_Name = _costDeliveryToPoint[0]?.accountName
                        _costDeliveryToPoint_parcelCostId = _costDeliveryToPoint[0]?.id
                    }

                    if (_costDeliveryToPoint[0]?.amount === 0) {
                        accountIdForCostDeliveryToPoint_Id = ""
                        accountIdForCostDeliveryToPoint_Name = ""
                        _costDeliveryToPoint_parcelCostId = 0
                    }

                    const data: any = {
                        ...value,
                        id: value.id,
                        code: value.code,
                        senderId: {
                            label: value.sender.fullName,
                            value: value.sender.id,
                        },
                        recipientStaffId: {
                            label: value.recipientStaff?.fullName,
                            value: value.recipientStaff?.id,
                        },
                        senderStaffId: {
                            label: value.senderStaff?.fullName,
                            value: value.senderStaff?.id,
                        },
                        recipientId: {
                            label:
                            value.recipient.fullName,
                            value: value.recipient.id,
                        },
                        parcelBranchFromId: {
                            label: value.fromBranch.name,
                            value: value.fromBranch.id,
                        },
                        parcelBranchToId: {
                            label: value.toBranch.name,
                            value: value.toBranch.id,
                        },
                        weight: value.parcelSize.weight,
                        images: value.parcelImage,
                        description: value.parcelDescription,
                        pickupAddress: value.parcelAddress.pickingUpAddress,
                        deliveryAddress: value.parcelAddress.deliveryAddress,
                        numberOfPoint: value.numberOfPoint,
                        parcelPlanId: {
                            label: value.parcelPlan?.name,
                            value: value.parcelPlan?.id,
                        },

                        parcelsTypeId: {
                            label: value.parcelType,
                            value: value.parcelTypeId,
                        },

                        costPickingUp_parcelCostId: value.parcelCost.filter((item: any) => item.costTypeId === 1)[0]?.id,
                        costPickingUp: value.parcelCost.filter((item: any) => item.costTypeId === 1)[0]?.amount,
                        statePickingUp: value.parcelCost.filter((item: any) => item.costTypeId === 1)[0]?.state,

                        costDeliveryToBranch_parcelCostId: value.parcelCost.filter((item: any) => item.costTypeId === 2)[0]?.id,
                        costDeliveryToBranch: value.parcelCost.filter((item: any) => item.costTypeId === 2)[0]?.amount,
                        stateDeliveryToBranch: value.parcelCost.filter((item: any) => item.costTypeId === 2)[0]?.state,

                        costDeliveryToPoint_parcelCostId: value.parcelCost.filter((item: any) => item.costTypeId === 3)[0]?.id,
                        costDeliveryToPoint: value.parcelCost.filter((item: any) => item.costTypeId === 3)[0]?.amount,
                        stateDeliveryToPoint: value.parcelCost.filter((item: any) => item.costTypeId === 3)[0]?.state,

                        accountIdForCostDeliveryToBranch: {
                            label: accountIdForCostDeliveryToBranch_Name,
                            value: accountIdForCostDeliveryToBranch_Id
                        },

                        accountIdForCostDeliveryToPoint: {
                            label: accountIdForCostDeliveryToPoint_Name,
                            value: accountIdForCostDeliveryToPoint_Id,
                        },

                        accountIdForCostPickingUp: {
                            label: accountIdForCostPickingUp_Name,
                            value: accountIdForCostPickingUp_Id,
                        },

                        paymentMethod: {
                            label: value.parcelCost?.paymentMethod?.name,
                            value: value.parcelCost?.paymentMethod?.id,
                        },

                        senderCourierId: value.senderCourier
                            ? {
                                label: value.senderCourier?.fullName,
                                value: value.senderCourier?.id,
                            }
                            : labelValue,

                        recipientCourierId: value.recipientCourier
                            ? {
                                label: value.recipientCourier?.fullName,
                                value: value.recipientCourier?.id,
                            }
                            : labelValue,

                        currencyTypeIdForPickingUp: {
                            label: value.parcelCost.filter((item: any) => item.costTypeId === 1)[0]?.currencyName,
                            value: value.parcelCost.filter((item: any) => item.costTypeId === 1)[0]?.currencyId,
                        },

                        currencyTypeIdForDeliveryToBranch: {
                            label: value.parcelCost.filter((item: any) => item.costTypeId === 2)[0]?.currencyName,
                            value: value.parcelCost.filter((item: any) => item.costTypeId === 2)[0]?.currencyId,
                        },

                        currencyTypeIdForDeliveryToPoint: {
                            label: value.parcelCost.filter((item: any) => item.costTypeId === 3)[0]?.currencyName,
                            value: value.parcelCost.filter((item: any) => item.costTypeId === 3)[0]?.currencyId,
                        },

                        stateSenderCourierId: false,
                        stateRecipientCourierId: false,

                        payerTypeId: {
                            label: value.payerTypeName,
                            value: value.payerTypeId,
                        },

                        merchantId: {
                            label: value.merchant ? value.merchant.name : '',
                            value: value.merchant ? value.merchant.id : 0,
                        },

                        payer: {
                            label: value.payer ? value.payer.fullName : '',
                            value: value.payer ? value.payer.id : '',
                        },
                    };
                    setInitialValues(data);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error.message);
                });
        }
    }, [parcelId, setInitialValues, ParcelApi]);


    /////SUBMIT
    const onSubmit = useCallback((value: any) => {

        const parcelCostList: any = [];

        if (value.senderId.value === "") {
            toast.error("Выберите отправителя!");
            return;
        }

        if (value.recipientId.value === "") {
            toast.error("Выберите получателя!");
            return;
        }

        if (value.parcelBranchToId.value == "" || value.parcelBranchToId.value == 0) {
            toast.error("Выберите филиал получателя!");
            return;
        }

        if (value.weight == "" || value.weight == 0) {
            toast.error("Вес посылки не должен быть 0!");
            return;
        }

        if (value.parcelPlanId.value == null || value.parcelPlanId.value == 0) {
            toast.error("Выберите тариф для отправки!");
            return;
        }

        if (value.payerTypeId.value === 3 && value.merchantId.value === 0) {
            toast.error("Выберите юр.лицо!");
            return;
        }

        if (value.parcelsTypeId.value == null || value.parcelsTypeId.value == 0) {

            toast.error("Выберите тип посылки!");
            return;
        }

        if ((value.costDeliveryToBranch == null && value.costDeliveryToBranchLocal == null) ||
            (value.costDeliveryToBranch == "0" && value.costDeliveryToBranchLocal == "0")) {
            toast.error("Определите стоимость перевозки!");
            return;
        }

        // Delivery to point usd/local for delete
        if (value.costDeliveryToPoint == "" && value.costDeliveryToPointLocal == "" || value.costDeliveryToPoint == "0") {
            console.log("Delivery to point usd/local for delete")
            if (value.id != null || value.id > 0) {
                parcelCostList.push({
                    id: value.costDeliveryToPoint_parcelCostId,
                });
            }
        }

        // Pickup
        if (value.costPickingUp > 0) {
            console.log("Picking up")

            if (value.statePickingUp && value.accountIdForCostPickingUp == null || value.statePickingUp && value.accountIdForCostPickingUp.value == null) {
                toast.error("Определите счет для оплаты забора!");
                return;
            }

            parcelCostList.push({
                id: value.costPickingUp_parcelCostId,
                amount: Number(value.costPickingUp),
                state: value.statePickingUp,
                costTypeId: 1,
                currencyId: value.currencyTypeIdForPickingUp.value,
                accountId: value.accountIdForCostPickingUp ? Number(value.accountIdForCostPickingUp?.value) : null
            });
        }

        // Delivery to branch
        if (value.costDeliveryToBranch > 0) {
            console.log("Delivery to branch")
            if (value.stateDeliveryToBranch && value.accountIdForCostDeliveryToBranch == null || value.stateDeliveryToBranch && value.accountIdForCostDeliveryToBranch.value == null) {
                toast.error("Определите счет для оплаты перевозки!");
                return;
            }
            parcelCostList.push({
                id: value.costDeliveryToBranch_parcelCostId,
                amount: Number(value.costDeliveryToBranch),
                state: value.stateDeliveryToBranch,
                costTypeId: 2,
                currencyId: value.currencyTypeIdForDeliveryToBranch.value,
                accountId: value.accountIdForCostDeliveryToBranch ? Number(value.accountIdForCostDeliveryToBranch?.value) : null
            });
        }

        // Delivery to point
        if (value.costDeliveryToPoint > 0) {
            console.log("Delivery to point")

            if (value.stateDeliveryToPoint && value.accountIdForCostDeliveryToPoint == null || value.stateDeliveryToPoint && value.accountIdForCostDeliveryToPoint.value == null) {
                toast.error("Определите счет для оплаты доставки!");
                return;
            }

            parcelCostList.push({
                id: value.costDeliveryToPoint_parcelCostId,
                amount: Number(value.costDeliveryToPoint),
                state: value.stateDeliveryToPoint,
                costTypeId: 3,
                currencyId: value.currencyTypeIdForDeliveryToPoint.value,
                accountId: value.accountIdForCostDeliveryToPoint ? Number(value.accountIdForCostDeliveryToPoint?.value) : null
            });
        }

        var customPayerId: any;
        var customPayerTypeId: String = '';
        if (value.payerTypeId != null && value.payerTypeId?.value === 1) {
            customPayerId = Number(value.senderId?.value)
            customPayerTypeId = "1"

        } else if (value.payerTypeId != null && value.payerTypeId?.value === 2) {
            customPayerId = Number(value.recipientId?.value)
            customPayerTypeId = "2"

        } else if (value.payerTypeId != null && value.payerTypeId?.value === 3) {
            customPayerId = null
            customPayerTypeId = "3"

        } else if (value.payerTypeId == null) {

            customPayerId = Number(value.senderId?.value)
            customPayerTypeId = "2"
        }


        setLoading(true);
        if (parcelId !== '') {

            const custom_images: any = [];
            value.images.map((item: any) => {
                const image_item = {
                    imageName: item.imageName,
                    imageBytes: item.imageBytes,
                };
                custom_images.push(image_item);
            });

            const data = {
                id: value.id,
                code: value.code,
                parcelCost: parcelCostList,
                senderId: Number(value.senderId?.value),
                recipientId: Number(value.recipientId?.value),
                senderStaffId: Number(profile.id),
                recipientCourierId: Number(value.recipientCourierId?.value),
                senderCourierId: Number(value.senderCourierId?.value),
                recipientStaffId: Number(value.recipientStaffId?.value),
                parcelPlanId: Number(value.parcelPlanId?.value),
                parcelBranchFromId: Number(value.parcelBranchFromId?.value),
                parcelBranchToId: Number(value.parcelBranchToId?.value),
                parcelStatusId: value.parcelStatusId?.value,
                parcelSize: {
                    weight: Number(value.weight),
                    numberOfPoint: Number(value.numberOfPoint),
                },
                numberOfPoint: value.numberOfPoint,
                parcelImage: custom_images,
                parcelAddress: {
                    pickingUpAddress: value.pickupAddress,
                    deliveryAddress: value.deliveryAddress,
                },
                parcelDescription: {
                    description: value.description,
                },
                sendSmsToRecipient: value.sendSmsToRecipient,
                sendSmsToSender: value.sendSmsToSender,
                sendSmsToTelegram: value.sendSmsToTelegram,
                payerId: customPayerId ? customPayerId : null,
                payerTypeId: customPayerTypeId,
                merchantId: Number(value.merchantId?.value),
                parcelItem: value.parcelItem,
                parcelsTypeId: value.parcelsTypeId.value,

            };

            ParcelApi.updateParcel(data)
                .then((response) => {
                    setLoading(false);
                    toast.success(response.message);
                    navigator('/app/parcels/all-parcels');
                })
                .catch((error: any) => {
                    setLoading(false);
                    toast.error(error.message);
                });
        } else {
            const data = {
                code: value.code,
                parcelCost: parcelCostList,
                senderId: Number(value.senderId.value),
                recipientId: Number(value.recipientId.value),
                senderStaffId: Number(profile.id),
                recipientCourierId: Number(value.recipientCourierId.value),
                recipientStaffId: Number(value.recipientStaffId?.value),
                senderCourierId: Number(value.senderCourierId.value),
                parcelPlanId: Number(value.parcelPlanId.value),
                parcelBranchFromId: Number(value.parcelBranchFromId.value),
                parcelBranchToId: Number(value.parcelBranchToId.value),
                parcelSize: {
                    weight: Number(value.weight),
                    numberOfPoint: Number(value.numberOfPoint),
                },
                numberOfPoint: value.numberOfPoint,
                parcelImage: value.images,
                parcelAddress: {
                    pickingUpAddress: value.pickupAddress,
                    deliveryAddress: value.deliveryAddress,
                },
                parcelDescription: {
                    description: value.description,
                },
                sendSmsToRecipient: value.sendSmsToRecipient,
                sendSmsToSender: value.sendSmsToSender,
                sendSmsToTelegram: value.sendSmsToTelegram,
                payerId: customPayerId,
                payerTypeId: customPayerTypeId,
                merchantId: Number(value.merchantId?.value),
                parcelItem: value.parcelItem,
                parcelsTypeId: value.parcelsTypeId.value
            };
            ParcelApi.createParcel(data)
                .then((response) => {
                    setLoading(false);
                    toast.success(response.message);
                    navigator('/app/parcels/all-parcels');
                })
                .catch((error: any) => {
                    setLoading(false);
                    // console.log(error)
                    toast.error(error.message);
                });
            // }
        }
    }, [profile, navigator, parcelId, ParcelApi, usdRate]);

    return (
        <TabPage childrenClassName="p-2">
            <AddParcelFormSecond
                senders={senders}
                type={parcelId ? 'update' : 'create'}
                recipients={recipients}
                recipientStaffs={couriers}
                parcelItemStatuses={parcelItemStatuses}
                customers={couriers}
                payerTypes={payerTypes}
                parcelTypes={parcelTypes}
                currencyTypes={currencyTypes}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                plans={plans}
                branches={branches}
                merchants={merchants}
                accounts={accounts}
                costInfo={costInfoList}
                usdRate={usdRate}
                usdRateList={usdRateList}
                setRandomCode={(value: any) => onPrint(value)}
                printParcelItem={(value: any) => printParcelItem(value)}
                onSubmit={(value) => onSubmit(value)}
                searchSender={(value: string) => getSendersBySearching(value)}
                searchRecipientStaff={(value: string) => getRecipientStaffsBySearching(value)}
                // searchMerchant={(value: string) =>
                //     getMerchantsBySearching(value)
                // }
                searchRecipient={(value: string) =>
                    getRecipientsBySearching(value)
                }
            />
            <ModalLoader open={loading}/>
        </TabPage>
    );
}
