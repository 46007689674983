import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import GroupBox from '../app/GroupBox';
import InputField from '../form/InputField';
import { update } from 'immupdate';
import Button from '../button/Button';
import SelectPickerField from '../form/SelectPickerField';
import { useShallowEqualSelector } from '../../hooks/useShallowSelector';
import { profileSelector } from '../../reducers/authReducer';
import { CheckUserRole } from '../../utils/CheckUserInfo';

interface BranchFormProps {
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly branches: any[];
    readonly submit: (value: any) => void;
}

const validationSchema = object({
    name: string(),
    kpp: string(),
    tin: string(),
    address: string(),
    email: string(),
    phone: string(),
    accountNumber: string(),
    bik: string(),
    bankMerchantAddress: string(),
    bankMerchant: string(),
    ceo: string(),
    chiefAccountant: string(),
    correspondentAccount: string(),
    ogrn: string(),
    ogrnip: string(),
    okpo: string(),
    okved: string(),
});

export default function MerchantForm({
    initialValues,
    setInitialValues,
    branches,
    submit,
}: BranchFormProps) {
    const profile: any = useShallowEqualSelector(profileSelector);

    const isAdmin = useMemo(
        () => CheckUserRole(profile?.role, 'Administrator'),
        [profile.role]
    );

    const onChangeName = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    name: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    // const onChangeTin = useCallback(
    //     async (value: any) => {
    //         const token = "113631dffc75281557122c991cbe0c1d28bd1f48";
    //         const query = value.target.value;
    //
    //         try {
    //             const result = await fetchTinData(query, token);
    //             console.log(result);
    //             setInitialValues((prev: any) =>
    //                 update(prev, {
    //                     tin: query,
    //                     name: result.suggestions[0] ? result.suggestions[0].unrestricted_value : "",
    //                     kpp: result.suggestions[0] ? result.suggestions[0].data.kpp : "",
    //                     ogrn: result.suggestions[0] ? result.suggestions[0].data.ogrn : "",
    //                     okpo: result.suggestions[0] ? result.suggestions[0].data.okpo : "",
    //                     okved: result.suggestions[0] ? result.suggestions[0].data.okved : "",
    //                     address: result.suggestions[0] ? result.suggestions[0].data.address.unrestricted_value : "",
    //                     ceo: result.suggestions[0] ? result.suggestions[0].data.management.name : "",
    //                 })
    //             );
    //         } catch (error) {
    //             console.error("error", error);
    //         }
    //     },
    //     [setInitialValues]
    // );

    const onChangeTin = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    tin: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeAddress = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    address: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeEmail = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    email: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangePhone = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    phone: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeCorrespondentAccount = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    correspondentAccount: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeBik = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    bik: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeAccountNumber = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    accountNumber: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeOkpo = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    okpo: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeCeo = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    ceo: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeBankMerchantAddress = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    bankMerchantAddress: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeChiefAccountant = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    chiefAccountant: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeBankMerchant = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    bankMerchant: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeOkved = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    okved: value.target.value,
                })
            );
        },
        [setInitialValues]
    );
    const onChangeOgrn = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    ogrn: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeOgrnIp = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    ogrnip: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeKpp = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    kpp: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeBranchId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    branchId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={() => submit(initialValues)}
        >
            {() => (
                <Form>
                    <div className="row">
                        <div className="col-12">
                            <GroupBox title="Контрагенты">
                                <div className="row">
                                    <div className="col-12">
                                        <InputField
                                            label="Наименование"
                                            name="name"
                                            value={initialValues.name}
                                            onChange={(event: any) =>
                                                onChangeName(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="ИНН"
                                            name="tin"
                                            value={initialValues.tin}
                                            onChange={(event: any) =>
                                                onChangeTin(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="КПП"
                                            name="kpp"
                                            value={initialValues.kpp}
                                            onChange={(event: any) =>
                                                onChangeKpp(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="ОГРН"
                                            name="ogrn"
                                            value={initialValues.ogrn}
                                            onChange={(event: any) =>
                                                onChangeOgrn(event)
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <InputField
                                            label="ОГРН ИП"
                                            name="ogrnip"
                                            value={initialValues.ogrnip}
                                            onChange={(event: any) =>
                                                onChangeOgrnIp(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="ОКПО"
                                            name="okpo"
                                            value={initialValues.okpo}
                                            onChange={(event: any) =>
                                                onChangeOkpo(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="ОКВЭД"
                                            name="okved"
                                            value={initialValues.okved}
                                            onChange={(event: any) =>
                                                onChangeOkved(event)
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <InputField
                                            label="Юр адрес"
                                            name="address"
                                            value={initialValues.address}
                                            onChange={(event: any) =>
                                                onChangeAddress(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="Банк"
                                            name="bankMerchant"
                                            value={initialValues.bankMerchant}
                                            onChange={(event: any) =>
                                                onChangeBankMerchant(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="Адрес банка"
                                            name="bankMerchantAddress"
                                            value={initialValues.bankMerchantAddress}
                                            onChange={(event: any) =>
                                                onChangeBankMerchantAddress(event)
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <InputField
                                            label="Расчетный счет"
                                            name="accountNumber"
                                            value={initialValues.accountNumber}
                                            onChange={(event: any) =>
                                                onChangeAccountNumber(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="Корреспондентский счёт"
                                            name="correspondentAccount"
                                            value={initialValues.correspondentAccount}
                                            onChange={(event: any) =>
                                                onChangeCorrespondentAccount(event)
                                            }
                                        />
                                    </div>


                                    <div className="col-12">
                                        <InputField
                                            label="Контакты"
                                            name="phone"
                                            value={initialValues.phone}
                                            onChange={(event: any) =>
                                                onChangePhone(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="Email"
                                            name="email"
                                            value={initialValues.email}
                                            onChange={(event: any) =>
                                                onChangeEmail(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="Генеральный директор"
                                            name="ceo"
                                            value={initialValues.ceo}
                                            onChange={(event: any) =>
                                                onChangeCeo(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <InputField
                                            label="Бухгалтер"
                                            name="chiefAccountant"
                                            value={initialValues.chiefAccountant}
                                            onChange={(event: any) =>
                                                onChangeChiefAccountant(event)
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <InputField
                                            label="Бик"
                                            name="bik"
                                            value={initialValues.bik}
                                            onChange={(event: any) =>
                                                onChangeBik(event)
                                            }
                                        />
                                    </div>


                                    {isAdmin && (
                                        <div className="col-12">
                                            <SelectPickerField
                                                label="Филиал"
                                                name="branchId"
                                                options={branches}
                                                onChanges={(event: any) =>
                                                    onChangeBranchId(event)
                                                }
                                            />
                                        </div>
                                    )}

                                </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button
                                type="submit"
                                className="text-light bg-gold px-2 py-1"
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
