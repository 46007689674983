import { useCallback, useState } from "react";
import Button from "../button/Button";
import AddIcon from "../icons/AddIcon";
import DeleteIcon from "../icons/DeleteIcon";
import SelectPickerField from "../form/SelectPickerField";
import PrintIcon from "../icons/PrintIcon";

export interface ItemsStatusProps{
    readonly id: number;
    readonly statusItemId: number;
    readonly isCurrent: boolean;
    readonly dateCreated: string;
    readonly statusItemName?: string;
}

export interface ItemsProps{
    readonly id: number;
    readonly code: number;
    readonly parcelItemStatus: ItemsStatusProps[];
}

interface Props{
    readonly type?: string;
    readonly parcelItems: any[];
    readonly parcelItemStatuses: any[];
    readonly onChangeValue: (value: any) => void;
    readonly printParcelItem: (value: any) => void;
    readonly addItem: () => void;
    readonly removeItem: (index: number) => void
}

export default function ParcelItemForm({
    type,
    parcelItems,
    parcelItemStatuses,
    onChangeValue,
    printParcelItem,
    addItem,
    removeItem
}:Props){

    const onChangeParcelItemStatus = useCallback((value: any, index: number)=>{

        const data: any = [...parcelItems];

        const parcelItemIndex = index;
        
        data[parcelItemIndex].parcelItemStatus.forEach((element: any) => {
            element.isCurrent = false
        });
        
        const newParcelItemStatus = {
            id: 0,
            isCurrent: true,
            statusItemId: value.value,
            statusItemName: value.label
        }

        data[parcelItemIndex].parcelItemStatus.push(newParcelItemStatus)

        onChangeValue(data);
        
    },[onChangeValue, parcelItems]);

    return (

        <div className="row">
            <div className="col-12">
            <table className="table table-bordered">
            <thead>
                <tr>
                <th colSpan={2}>#</th>
                <th colSpan={3}>Номер место</th>
                <th colSpan={3}>Дата</th>
                <th colSpan={3}>Статус</th>
                <th colSpan={2}>...</th>
                </tr>
            </thead>
            <tbody>
                {parcelItems.map((item: ItemsProps, index: number)=>{

                    const statuses: any = [];

                    item.parcelItemStatus.map((status: ItemsStatusProps)=>{
                        const option = {
                            label: status.statusItemName,
                            value: status.statusItemId,
                            isCurrent: status.isCurrent
                        }

                        statuses.push(option)
                    })

                    const correctStatus = item.parcelItemStatus.filter((dd: any)=>Boolean(dd.isCurrent))[0];

                    return (
                        <tr key={index}>
                            <td colSpan={2}>{index+1}</td>
                            <td colSpan={3}>{item.code}</td>
                            <td colSpan={3}>{item.parcelItemStatus[0]?.dateCreated}</td>
                            <td colSpan={3}>
                                <SelectPickerField
                                        options={parcelItemStatuses.filter((itemStatus: any)=>itemStatus.value > correctStatus.statusItemId)}
                                        className="bg-light"
                                        name="status"
                                        disabled={type === "create"? true : false}
                                        onChanges={(value)=>onChangeParcelItemStatus(value, index)}
                                        value={statuses.filter((s:any)=>s.isCurrent === true)}
                                        />
                            </td>
                            <td colSpan={2}>
                               <div className="d-flex">
                               <Button 
                                    className="text-light py-2 px-2 bg-danger"
                                    onClick={()=>removeItem(index)}
                                    >
                                    <DeleteIcon color="white" size={15}/>

                                </Button>
                                   {type !== "create" && (
                                <Button 
                                    className="text-light py-2 px-2 bg-success ms-2"
                                    onClick={()=>printParcelItem(item.id)}
                                    >
                                    <PrintIcon color="white" size={15}/>
                                </Button>
                                    )}
                               </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
            </table>
            </div>
            <div className="col-6"></div>
            <div className="col-6 d-flex justify-content-end align-items-center">
                {/*<Button */}
                {/*      className="bg-success text-light px-2"*/}
                {/*     onClick={()=>addItem()}*/}
                {/*    disabled={type === "create" ? true : false}*/}
                {/*    > */}
                {/*        <AddIcon color="white"/> */}
                {/*        <span className="ms-2">*/}
                {/*            Добавить место*/}
                {/*        </span>*/}
                {/*</Button>*/}

                {/*{type !== "create" && (*/}
                    <Button
                        className="bg-success text-light px-2"
                        onClick={() => addItem()}
                    >
                        <AddIcon color="white" />
                        <span className="ms-2">
            Добавить место
        </span>
                    </Button>
                {/*)}*/}
            </div>
        </div>
    )
}