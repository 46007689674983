import React, {useCallback, useMemo, useRef, useState} from 'react';

import {AgGridReact} from 'ag-grid-react';
import moment from "moment-timezone";
import 'ag-grid-enterprise';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import EditIcon from "../icons/EditIcon";
import PrintIcon from "../icons/PrintIcon";
import EyeIcon from "../icons/EyeIcon";

import {ISetFilter, PaginationNumberFormatterParams} from 'ag-grid-community';

import {
    ModuleRegistry,
    SideBarDef,
    IDateFilterParams,
} from '@ag-grid-community/core';

import {MenuModule} from '@ag-grid-enterprise/menu';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";

import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import DeleteIcon from "../icons/DeleteIcon";
import SendIcon from "../icons/SendIcon";
import ModalLoader from "../modal/MdalLoader";
import {clearLocalStorage, getLocalStorage, setLocalStorage} from "./utils/utility";
import {ColDef, GridApi} from "ag-grid-enterprise";

ModuleRegistry.registerModules([ClientSideRowModelModule, MenuModule, ExcelExportModule, ColumnsToolPanelModule, SetFilterModule]);

export default function ParcelTable() {
    const [isLoading, setIsLoading] = useState(false);
    const {ParcelApi} = useParcelApiContext();
    const navigator = useNavigate();
    const [isSendToTelegramModal, setIsSendToTelegramModal] = useState<boolean>(false);
    const [gridApi, setGridApi] = useState();
    const editButton = (params: any) => {
        navigator(`/app/parcel-form?parcelId=${params.data.id}`);
    }
    const viewButton = (params: any) => {
        navigator(`/app/parcels/parcel-view?parcelId=${params.data.id}`);
    }

    const printButtonSticker = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to print?");
        if (userConfirmed) {
            setIsLoading(true);
            ParcelApi.getStickerById({id: params.data.id, code: params.data.code})
                .then(() => {
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const printButtonInvoice = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to print?");
        if (userConfirmed) {
            setIsLoading(true);
            ParcelApi.getInvoice({id: params.data.id, currencyId: 1})
                .then(() => {
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };


    const deleteParcel = useCallback((id: any) => {
        ParcelApi.deleteParcel({id: [id]})
            .then((response) => {
                toast.success(response.message);
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [ParcelApi]);

    const sendToTelegramModal = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to send?");
        if (userConfirmed) {
            sendPost(params.data.id);
        }
    };

    const deleteParcelModal = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to delete?");
        if (userConfirmed) {
            deleteParcel(params.data.id);
        }
    };

    const sendPost = useCallback((id: any) => {
        // console.log(id)
        ParcelApi.sendToTelegramById({parcelIds: [id]})
            .then((response) => {
                toast.success(response.message);
                setIsSendToTelegramModal(false);
                // window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [isSendToTelegramModal, ParcelApi]);

    var dateFilterParams: IDateFilterParams = {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split(".");
            var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0]),
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2024,
        maxValidYear: 2025,
        inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    };

    const [initialValues, setInitialValues] = useState({
        code: "",
        sender: "",
        recipient: "",
        senderCourier: "",
        recipientCourier: "",

        senderStaff: "",
        recipientStaff: "",

        numberOfPoint: "",
        planId: "",
        statusId: "",
        fromDate: "",
        toDate: "",
        fromDateStatus: "",
        toDateStatus: ""
    });

    const profile: any = useShallowEqualSelector(profileSelector);

    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);
            return hasRole.length > 0;
        },
        [profile]
    );

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
                {
                    id: "save",
                    labelDefault: "Настройки",
                    labelKey: "save",
                    iconKey: "menu",
                    toolPanel: () => (
                        <div style={{marginTop: 25}}>
                            <button onClick={saveState}>Сохранить</button>
                            <br></br>
                            <br></br>
                            {/*<button onClick={() => restoreState(gridApi)}>*/}
                            {/*    Restore State*/}
                            {/*</button>*/}
                            <button onClick={resetState}>Сброс</button>
                        </div>
                    ),
                }
            ],
        };
    }, []);

    let selectedFromBranch: string[] | null = null;
    let selectedToBranch: string[] | null = null;
    let selectedPlan: string[] | null = null;
    let selectedParcelType: string[] | null = null;
    let selectedStatus: string[] | null = null;
    let selectedPayer: string[] | null = null;
    let selectedDate: string | null = null;

    const onFilterChanged = useCallback(() => {
        // fromBranch
        let fromBranchFilterModel = gridRef.current!.api.getFilterModel()["fromBranch"];
        let selectedF = fromBranchFilterModel && fromBranchFilterModel.values;
        if (!areEqual(selectedFromBranch, selectedF)) {
            selectedFromBranch = selectedF;
            // console.log("Refreshing toBranch filter" + selectedFromBranch);
            gridRef.current!.api.getColumnFilterInstance<ISetFilter>("fromBranch")
                .then((fromBranch: any) => {
                    fromBranch!.refreshFilterValues();
                });
        }

        // toBranch
        let toBranchFilterModel = gridRef.current!.api.getFilterModel()["toBranch"];
        let selectedT = toBranchFilterModel && toBranchFilterModel.values;
        if (!areEqual(selectedToBranch, selectedT)) {
            selectedToBranch = selectedT;
            // console.log("Refreshing toBranch filter" + selectedToBranch);
            gridRef.current!.api.getColumnFilterInstance<ISetFilter>("toBranch")
                .then((toBranch: any) => {
                    toBranch!.refreshFilterValues();
                });
        }

        // parcelPlan
        var planFilterModel = gridRef.current!.api.getFilterModel()["parcelPlan"];
        var selectedP = planFilterModel && planFilterModel.values;
        if (!areEqual(selectedPlan, selectedP)) {
            selectedPlan = selectedP;
            // console.log("Refreshing parcelPlan filter " + selectedPlan);
            gridRef.current!.api.getColumnFilterInstance<ISetFilter>("parcelPlan")
                .then((parcelPlan: any) => {
                    parcelPlan!.refreshFilterValues();
                });
        }

        //parcelType
        var parcelTypeFilterModel = gridRef.current!.api.getFilterModel()["parcelType"];
        var selectedPT = parcelTypeFilterModel && parcelTypeFilterModel.values;
        if (!areEqual(selectedParcelType, selectedPT)) {
            selectedParcelType = selectedPT;
            // console.log("Refreshing parcelPlan filter " + selectedPlan);
            gridRef.current!.api.getColumnFilterInstance<ISetFilter>("parcelType")
                .then((parcelType: any) => {
                    parcelType!.refreshFilterValues();
                });
        }

        //payer
        var payerFilterModel = gridRef.current!.api.getFilterModel()["payer"];
        var selectedPM = payerFilterModel && payerFilterModel.values;
        if (!areEqual(selectedPayer, selectedPM)) {
            selectedPayer = selectedPM;
            gridRef.current!.api.getColumnFilterInstance<ISetFilter>("payer")
                .then((payer: any) => {
                    payer!.refreshFilterValues();
                });
        }

        // generalStatus
        var statusFilterModel = gridRef.current!.api.getFilterModel()["generalStatus"];
        var selectedS = statusFilterModel && statusFilterModel.values;
        if (!areEqual(selectedStatus, selectedS)) {

            selectedStatus = selectedS;
            // console.log("Refreshing generalStatus filter " + selectedStatus);
            gridRef.current!.api.getColumnFilterInstance<ISetFilter>("generalStatus")
                .then((generalStatus: any) => {
                    generalStatus!.refreshFilterValues();
                });
        }

    }, [selectedFromBranch, selectedToBranch, selectedPlan, selectedStatus, selectedDate, selectedPayer]);


    function areEqual(a: null | string[], b: null | string[]) {
        if (a == null && b == null) {
            return true;
        }
        if (a != null || b != null) {
            return false;
        }
        return (
            a!.length === b!.length &&
            a!.every(function (v, i) {
                return b![i] === v;
            })
        );
    }


    const gridRef = useRef<AgGridReact>(null);

    const onGridReady = (params: any) => {
        restoreState(params);
        setGridApi(params);
        params.api.updateGridOptions({serverSideDatasource: datasource})
    }

    const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [20, 50, 100, 10000];
    }, []);

    const paginationNumberFormatter = useCallback(
        (params: PaginationNumberFormatterParams) => {
            return "[" + params.value.toLocaleString() + "]";
        },
        [],
    );

    const saveState = () => {
        const colState = gridRef.current!.api.getColumnState();
        setLocalStorage(colState);
        closeSidebarToolPanel();
    };


    const restoreState = (params: any) => {

        const colState = getLocalStorage();
        //console.log(colState)
        if (!colState) {
            return;
        }
        params.columnApi.applyColumnState({
            state: colState,
            applyOrder: true,
        });
       // console.log(params.columnApi)
        closeSidebarToolPanel();
    };


    const resetState = () => {
        gridRef.current!.api.resetColumnState();
        clearLocalStorage("grid");
        closeSidebarToolPanel();
    };

    const closeSidebarToolPanel = () => [gridRef.current!.api.closeToolPanel];


    const gridOptions = {
        sideBar: sideBar,
        getRowId: (params: any) => {
            return params.data.id;
        }
    };

    const datasource = {
        getRows(params: any) {
            const {startRow, endRow, filterModel, sortModel} = params.request
            let count = params.request.endRow - params.request.startRow; // or the cacheBlockSize
            let page = Math.ceil(params.request.endRow / count);

            const filterKeysNumberOfPoint = Object.keys(params.request.filterModel)
            filterKeysNumberOfPoint.forEach(filter => {
                if (filterKeysNumberOfPoint[0] === "numberOfPoint") {
                    initialValues.numberOfPoint = "";
                    initialValues.numberOfPoint = `${filterModel[filter].filter}`
                }
            })

            //code
            const filterKeys = Object.keys(filterModel)
            filterKeys.forEach(filter => {
                if (filterKeys[0] === "code") {
                    initialValues.code = "";
                    initialValues.code += `${filterModel[filter].filter}`
                }
            })

            //SenderCourier
            const filterKeysForSenderCourier = Object.keys(filterModel)
            filterKeysForSenderCourier.forEach(filter => {
                if (filterKeysForSenderCourier[0] === "senderCourier") {
                    initialValues.senderCourier = "";
                    initialValues.senderCourier += `${filterModel[filter].filter}`
                }
            })

            //RecipientCourier
            const filterKeysForRecipientCourier = Object.keys(filterModel)
            filterKeysForRecipientCourier.forEach(filter => {
                if (filterKeysForRecipientCourier[0] === "recipientCourier") {
                    initialValues.recipientCourier = "";
                    initialValues.recipientCourier += `${filterModel[filter].filter}`
                }
            })

            //Sender
            const filterKeysForSender = Object.keys(filterModel)
            filterKeysForSender.forEach(filter => {
                if (filterKeysForSender[0] === "sender") {
                    initialValues.sender = "";
                    initialValues.sender += `${filterModel[filter].filter}`
                }
            })

            //Recipient
            const filterKeysForRecipient = Object.keys(filterModel)
            filterKeysForRecipient.forEach(filter => {
                if (filterKeysForRecipient[0] === "recipient") {
                    initialValues.recipient = "";
                    initialValues.recipient += `${filterModel[filter].filter}`
                }
            })

            //RecipientStaff
            const filterKeysForRecipientStaff = Object.keys(filterModel)
            filterKeysForRecipientStaff.forEach(filter => {
                if (filterKeysForRecipientStaff[0] === "recipientStaff") {
                    initialValues.recipientStaff = "";
                    initialValues.recipientStaff += `${filterModel[filter].filter}`
                }
            })

            //SenderStaff
            const filterKeysForSenderStaff = Object.keys(filterModel)
            filterKeysForSenderStaff.forEach(filter => {
                if (filterKeysForSenderStaff[0] === "senderStaff") {
                    initialValues.senderStaff = "";
                    initialValues.senderStaff += `${filterModel[filter].filter}`
                }
            })

            const filterKeysFromDate = Object.keys(filterModel)

            filterKeysFromDate.forEach(filter => {
                if (filterKeys[0] === "dateCreatedString") {
                    if (filterModel[filter].dateFrom !== null) {
                        initialValues.fromDate = "";
                        initialValues.fromDate += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        initialValues.toDate = "";
                        initialValues.toDate += `${filterModel[filter].dateTo}`
                    }
                }
            })

            const filterKeysFromDateStatus = Object.keys(filterModel)
            filterKeysFromDateStatus.forEach(filter => {
                if (filterKeys[0] === "dateCreatedStatus") {
                    if (filterModel[filter].dateFrom !== null) {
                        initialValues.fromDateStatus = "";
                        initialValues.fromDateStatus += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        initialValues.toDateStatus = "";
                        initialValues.toDateStatus += `${filterModel[filter].dateTo}`
                    }
                }
            })

            const data = {
                pageNumber: page,
                pageSize: count === 0 ? 1 : count,
                code: Number(initialValues.code),
                sender: initialValues.sender,
                recipient: initialValues.recipient,

                senderCourier: initialValues.senderCourier,
                recipientCourier: initialValues.recipientCourier,

                senderStaff: initialValues.senderStaff,
                recipientStaff: initialValues.recipientStaff,

                numberOfPoint: Number(initialValues.numberOfPoint),
                fromBranch: selectedFromBranch || ['Москва', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул', 'Ташкент'],
                toBranch: selectedToBranch || ['Москва', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул', 'Ташкент'],
                planId: selectedPlan || ['Стандарт', 'Экспресс', 'Ультра', 'Авто', 'Авто-Эконом'],
                statusId: selectedStatus || ['Создан', 'В пути', 'Прибыл', 'У курьера'],
                parcelTypeId: selectedParcelType || ['Посылка', 'Документ', 'Мед'],
                payerType: selectedPayer || ['Отправитель', 'Получатель', 'Юр. лицо (ВЫСТАВЛЕН СЧЕТ)'],
                dateCreateStart: initialValues.fromDate,
                dateCreateEnd: initialValues.toDate,
                dateCreateStatusStart: initialValues.fromDateStatus,
                dateCreateStatusEnd: initialValues.toDateStatus,
            };

            ParcelApi.getAllParcelCustom(data)
                .then((response: any) => {
                    params.success({
                        rowData: response.items,
                        rowCount: response.items.totalCount,
                    });
                    initialValues.numberOfPoint = ""
                    initialValues.code = ""
                    initialValues.sender = ""
                    initialValues.recipient = ""
                    initialValues.senderCourier = ""
                    initialValues.recipientCourier = ""
                    initialValues.senderStaff = ""
                    initialValues.recipientStaff = ""

                })
                .catch((error: any) => {
                    if (error.message === '') {
                        toast.error('Посылка не найдена!');
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    };

    const columnDefs = [
        // {
        //     headerName: "...",
        //     minWidth: 30,
        //     maxWidth: 60,
        //     checkboxSelection: true
        // },
        {
            headerName: "№",
            field: "id",
            minWidth: 70,
            maxWidth: 70,
            // filter: "agNumberColumnFilter"
        },
        {
            field: "code",
            headerName: "Код",
            minWidth: 85,
            maxWidth: 120,
            filter: "agNumberColumnFilter", /*floatingFilter: true,*/
        },
        {
            headerName: "Отправитель",
            field: "sender",
            // filter: true,
            filter: "agTextColumnFilter",/*floatingFilter: true*/
        },
        {
            headerName: "Получатель",
            field: "recipient",
            // filter: true, /*floatingFilter: true*/
            filter: "agTextColumnFilter",/*floatingFilter: true*/
        },
        {
            headerName: "Тип",
            field: "parcelType",
            // filter: true, /*floatingFilter: true*/
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Посылка', 'Документ', 'Мед']
            },
            hide: true,
            minWidth: 100,
            maxWidth: 100
        },
        {
            headerName: "Менеджер",
            field: "recipientStaff",
            hide: true,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Оператор",
            field: "senderStaff",
            hide: true,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Забор",
            field: "senderCourier",
            hide: true,
            filter: "agTextColumnFilter",/*floatingFilter: true*/
        },
        {
            headerName: "Доставка",
            field: "recipientCourier",
            hide: true,
            filter: "agTextColumnFilter",/*floatingFilter: true*/
        },
        {
            headerName: "Плательщик",
            field: "payer",
            hide: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Отправитель', 'Получатель', 'Юр. лицо (ВЫСТАВЛЕН СЧЕТ)']
            }
        },
        {
            headerName: "Контрагент",
            field: "merchant",
            hide: true,
            // filter: true,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "От",
            field: "fromBranch",
            minWidth: 100,
            maxWidth: 100,
            /*filter: "agNumberColumnFilter",*/
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            }
        },
        {
            headerName: "До",
            field: "toBranch",
            minWidth: 100,
            maxWidth: 100,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            }
        },
        {
            headerName: "Тариф",
            field: "parcelPlan",
            minWidth: 100,
            maxWidth: 100,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Стандарт', 'Экспресс', 'Ультра', 'Авто', 'Авто-Эконом']
            }
        },
        {
            headerName: "Вес",
            field: "parcelSize",
            minWidth: 100,
            maxWidth: 100,
            filter: false
        },
        {
            headerName: "Место",
            field: "numberOfPoint",
            minWidth: 90,
            maxWidth: 90,
            filter: false
            // filter: "agNumberColumnFilter"
        },
        {
            headerName: "Итого в USD",
            field: "generalStateUsd",
            minWidth: 120,
            maxWidth: 120,
            cellRenderer: function (row: any) {
                //Oplachen
                if (row.data.generalStateUsd && row.data.totalAmountUsd > 0) {
                    return (
                        <div style={{color: 'green', fontWeight: 'normal'}}>
                            {parseFloat(row.data.totalAmountUsd)} $
                        </div>
                    );
                }
                //NeOplachen
                else if (!row.data.generalStateUsd && row.data.totalAmountUsd > 0) //danger
                {
                    return (
                        <div style={{color: 'red', fontWeight: 'normal'}}>
                            {parseFloat(row.data.totalAmountUsd)} $
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Сотояние в USD",
            field: "generalStateUsd",
            minWidth: 120,
            maxWidth: 120,
            hide: true,
            cellRenderer: function (row: any) {
                //Oplachen
                if (row.data.totalAmountUsd !== 0 && row.data.generalStateUsd) {
                    return (
                        <div style={{color: 'green', fontWeight: 'normal'}}>
                            Оплачено
                        </div>
                    );
                }
                //NeOplachen
                else if (row.data.totalAmountUsd !== 0 && !row.data.generalStateUsd) //danger
                {
                    return (
                        <div style={{color: 'red', fontWeight: 'normal'}}>
                            Не оплачено
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Итого в местной валюте",
            field: "generalStatusLocal",
            minWidth: 120,
            maxWidth: 120,
            cellRenderer: function (row: any) {
                //Oplachen
                if (row.data.generalStateLocal && row.data.totalAmountLocal > 0) {
                    return (
                        <div style={{color: 'green', fontWeight: 'normal'}}>
                            {parseFloat(row.data.totalAmountLocal)}
                        </div>
                    );
                }
                //NeOplachen
                else if (!row.data.generalStateLocal && row.data.totalAmountLocal > 0) //danger
                {
                    return (
                        <div style={{color: 'red', fontWeight: 'normal'}}>
                            {parseFloat(row.data.totalAmountLocal)}
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Сотояние в местной валюте",
            field: "generalStateLocal",
            minWidth: 120,
            maxWidth: 120,
            hide: true,
            cellRenderer: function (row: any) {
                //Oplachen
                if (row.data.generalStateLocal && row.data.totalAmountLocal !== 0) {
                    return (
                        <div style={{color: 'green', fontWeight: 'normal'}}>
                            Оплачено
                        </div>
                    );
                }
                //NeOplachen
                else if (!row.data.generalStateLocal && row.data.totalAmountLocal !== 0) //danger
                {
                    return (
                        <div style={{color: 'red', fontWeight: 'normal'}}>
                            Не оплачено
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Статус",
            field: "generalStatus",
            minWidth: 90,
            maxWidth: 90, /*, cellStyle: {textAlign: 'center'}*/
            cellRenderer: function (row: any) {
                if (row.data.generalStatus === "Доставлен" || row.data.generalStatus === "Выдан") {
                    return (
                        <div style={{color: 'green', fontWeight: 'normal'}}>
                            {row.data.generalStatus}
                        </div>
                    );
                } else if (row.data.generalStatus !== "Доставлен" || row.data.generalStatus !== "Выдан") {
                    return (
                        <div style={{color: 'black', fontWeight: 'normal'}}>
                            {row.data.generalStatus}
                        </div>
                    );
                }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Создан', 'В пути', 'Прибыл', 'У курьера', 'Доставлен', 'Выдан']
            }

        },
        {
            headerName: "Дата статуса",
            minWidth: 130,
            maxWidth: 130,
            hide: true,
            cellRenderer: function (row: any) {
                return moment(row.data.generalStatusDateTime).format('DD.MM.yyyy HH:mm')
            }
        },
        {
            headerName: "Прошло",
            field: "parcelOverdue",
            minWidth: 90,
            maxWidth: 90,
            filter: false,
            hide: false,
            cellRenderer: function (row: any) {
                if (row.data.generalStatus !== "Доставлен") {
                    if (row.data.generalStatus !== "Выдан") {
                        //Standart 5-7
                        if (row.data.parcelPlan === "Стандарт") {
                            if (row.data.parcelOverdue >= 5 && row.data.parcelOverdue <= 7) //warning orange
                            {
                                return ( // 5 dney 6 dney 7 dney
                                    <div style={{color: 'orange', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дней
                                    </div>
                                );
                            } else if (row.data.parcelOverdue > 7) //danger red
                            {
                                return ( // 7 dney 8 dney 9 dney ...
                                    <div style={{color: 'red', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дней
                                    </div>
                                );
                            } else if (row.data.parcelOverdue === 0 || row.data.parcelOverdue === 1) //simple black
                            {
                                return ( // 0 den 1 den
                                    <div style={{color: 'black', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} день
                                    </div>
                                );
                            } else if (row.data.parcelOverdue >= 2 || row.data.parcelOverdue < 5) //simple black
                            {
                                return ( // 2 dnya 3 dnya 4 dnya
                                    <div style={{color: 'black', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дня
                                    </div>
                                );
                            }

                        }

                        //Express 3-5
                        if (row.data.parcelPlan === "Экспресс") {
                            if (row.data.parcelOverdue >= 3 && row.data.parcelOverdue <= 5) //warning orange
                            {
                                if (row.data.parcelOverdue === 3 || row.data.parcelOverdue === 4) {
                                    return ( //3 dnya 4 dnya
                                        <div style={{color: 'orange', fontWeight: 'normal'}}>
                                            {row.data.parcelOverdue} дня
                                        </div>
                                    );
                                } else if (row.data.parcelOverdue === 5) {
                                    return ( // 5 dney
                                        <div style={{color: 'orange', fontWeight: 'normal'}}>
                                            {row.data.parcelOverdue} дней
                                        </div>
                                    );

                                }
                            } else if (row.data.parcelOverdue > 5) //danger red
                            {
                                return ( // 5 dney
                                    <div style={{color: 'red', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дней
                                    </div>
                                );
                            }
                            if (row.data.parcelOverdue === 0 || row.data.parcelOverdue === 1) { //simple black
                                return ( // 0 den 1 den
                                    <div style={{color: 'black', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} день
                                    </div>
                                );
                            } else if (row.data.parcelOverdue === 2) { //simple black
                                return (  // 2 dnya
                                    <div style={{color: 'black', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дня
                                    </div>
                                );
                            }
                        }

                        //Ultra 36 chas
                        if (row.data.parcelPlan === "Ультра") {

                            if (row.data.parcelOverdue === 1) //warning orange
                            {
                                return ( //1 den
                                    <div style={{color: 'orange', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} день
                                    </div>
                                );
                            } else if (row.data.parcelOverdue >= 2 && row.data.parcelOverdue <= 4) //danger red
                            {
                                return ( //2 dnya 3 dnya 4 dnya
                                    <div style={{color: 'red', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дня
                                    </div>
                                );
                            } else if (row.data.parcelOverdue >= 5) { // danger red
                                return ( //5 dney 6 dney
                                    <div style={{color: 'red', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дней
                                    </div>
                                );
                            } else if (row.data.parcelOverdue === 0) { // simple black
                                return ( // 0 den
                                    <div style={{color: 'black', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} день
                                    </div>
                                );
                            }
                        }

                        //Avto 10
                        if (row.data.parcelPlan === "Авто" || row.data.parcelPlan === "Авто-Эконом") {
                            if (row.data.parcelOverdue >= 10 && row.data.parcelOverdue <= 15) //warning orange
                            {
                                return (
                                    <div style={{color: 'orange', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дней
                                    </div>
                                );
                            } else if (row.data.parcelOverdue > 15) //danger red
                            {
                                return ( //16 dney 17 dney ...
                                    <div style={{color: 'red', fontWeight: 'normal'}}>
                                        {row.data.parcelOverdue} дней
                                    </div>
                                );
                            } else if (row.data.parcelOverdue < 10) { // simple black
                                if (row.data.parcelOverdue === 1 || row.data.parcelOverdue === 0) {
                                    return (
                                        <div style={{color: 'black', fontWeight: 'normal'}}>
                                            {row.data.parcelOverdue} день
                                        </div>
                                    );
                                } else if (row.data.parcelOverdue === 2 || row.data.parcelOverdue === 3 || row.data.parcelOverdue === 4) {
                                    return (
                                        <div style={{color: 'black', fontWeight: 'normal'}}>
                                            {row.data.parcelOverdue} дня
                                        </div>
                                    );
                                } else if (row.data.parcelOverdue >= 5 && row.data.parcelOverdue < 10) {
                                    return (
                                        <div style={{color: 'black', fontWeight: 'normal'}}>
                                            {row.data.parcelOverdue} дней
                                        </div>
                                    );
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            headerName: 'Дата',
            field: 'dateCreatedString',
            minWidth: 90,
            maxWidth: 90,
            // cellRenderer: (p: any) => {
            //     return moment(p.value).format('DD.MM.yyyy HH:mm')
            // },
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
        },
        {
            headerName: "...",
            filter: false,
            field: "price",
            minWidth: checkRole('Administrator') ? 180 : 135,
            maxWidth: checkRole('Administrator') ? 180 : 135,
            cellRenderer: (params: any) =>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className={'btn btn-sm'} onClick={() => editButton(params)}><EditIcon
                        color="green" size={10}/></button>
                    <button type="button" className={'btn btn-sm'} onClick={() => viewButton(params)}><EyeIcon
                        color="orange" size={10}/></button>
                    <button type="button" className={'btn btn-sm'} onClick={() => printButtonSticker(params)}>
                        <PrintIcon color="red" size={10}/></button>
                    <button type="button" className={'btn btn-sm'} onClick={() => printButtonInvoice(params)}><PrintIcon
                        color="black" size={10}/></button>
                    {checkRole('Administrator') &&
                        <button type="button" className={'btn btn-sm'} onClick={() => sendToTelegramModal(params)}>
                            <SendIcon
                                color="blue" size={10}/></button>
                    }
                    {checkRole('Administrator') &&
                        <button type="button" className={'btn btn-sm'} onClick={() => deleteParcelModal(params)}>
                            <DeleteIcon
                                color="red" size={10}/></button>
                    }
                </div>
        },
    ];

    return (
        <div
            className="ag-theme-balham"
            style={{height: '100%'}}
        >
            <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                rowSelection={'multiple'}
                pagination={true}
                paginationPageSize={20}
                enableCellTextSelection={true}
                rowModelType={"serverSide"}
                onFilterChanged={onFilterChanged}
                rowBuffer={10000}
                cacheBlockSize={20}
                enableRangeSelection={true}
                maxBlocksInCache={2}
                sideBar={sideBar}
                gridOptions={gridOptions}
                paginationPageSizeSelector={paginationPageSizeSelector}
                paginationNumberFormatter={paginationNumberFormatter}
                suppressMenuHide
                defaultColDef={{filter: true, floatingFilter: true, sortable: true}}
            />
            <ModalLoader open={isLoading}/>
        </div>
    );
}